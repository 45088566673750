import axios from 'axios'
import {
  Loading
} from 'element-ui';
import router from '../router'
import {
  MessageBox
} from 'element-ui';
let ms = 0
export default function (config, data, noLoading) {
  if (!noLoading) {
    var loading = Loading.service({
      fullscreen: true
    });
  }

//   let formData;
//   if (!formTrue) {
//     formData = new FormData();
//     for (let it in data) {
//       formData.append(it, data[it]);
//     }
//   } else {
//     formData = data;
//   }
let formData = new FormData();
    for (let it in data) {
      formData.append(it, data[it]);
    }
  console.log('formData', formData)
  return new Promise((resolve, reject) => {

    // 创建axios实例
    const instance = axios.create({
    //   baseURL: '/request',
      timeout: 10000,
      method: 'post',
    //   header: {
    //     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    //   },
      data: formData
    })
    // 发送网络请求
    instance(config).then(res => {

      // 登录失效
      if (res.data.code == 403) {
        if (res.data.message == '登陆失效') {
          if (ms == 0) {
            ms++
            MessageBox.alert(res.data.message, "提示", {
              confirmButtonText: "确定",
              callback: () => {
                router.replace('/login')
              },
            })
          }
          loading.close()
          return
        }


      }
      resolve(res)

      if (!noLoading) {

        loading.close()

      }

    }).catch(err => {
      reject(err)
      if (!noLoading) {
        loading.close()
      }

    })
  })
}